import React from 'react'
import { Image } from 'components/Image'
import { Iframe } from 'components/Iframe'
import { Link } from 'components/Link'
import { Button } from 'components/Button'

export const calculateTime = content => {
  const wordsPerMinute = 200
  const words = content.split(' ').length
  return Math.ceil(words / wordsPerMinute)
}

export const toHref = text => {
  return text.toLowerCase().trim().replace(/^\s*|\s+/g, '-').replace(/-#*-/g, () => '#')
}

export const HeadingWithLink = ({ level, children }) => {
  const id = children.toString().toLowerCase().replace(/\s/g, '-')
  const href = `#${id}`
  switch (level) {
    case 1:
      return <h1 id={id}><Link href={href} target='_self'>{children}</Link></h1>
    case 2:
      return <h2 id={id}><Link href={href} target='_self'>{children}</Link></h2>
    case 3:
      return <h3 id={id}><Link href={href} target='_self'>{children}</Link></h3>
    case 4:
      return <h4 id={id}><Link href={href} target='_self'>{children}</Link></h4>
    default:
      return null
  }
}

export const markdownOptions = {
  overrides: {
    a: { component: Link, props: { underlined: true, colored: true } },
    button: { component: Button, props: { variant: 'contained', color: 'primary' } },
    img: { component: Image },
    iframe: { component: Iframe, props: { aspectRatio: 16 / 9, borderRadius: '10px' } },
    h1: { component: HeadingWithLink, props: { level: 1 } },
    h2: { component: HeadingWithLink, props: { level: 2 } },
    h3: { component: HeadingWithLink, props: { level: 3 } },
    h4: { component: HeadingWithLink, props: { level: 4 } },
  },
}
